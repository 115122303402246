import * as tslib_1 from "tslib";
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { parse, stringify } from 'querystring';
import { CbTrainingAppService } from './cb-training-app.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./cb-training-app.service";
// import { LogFactory } from './cb-debug';
// const log = LogFactory('CbAuthURLService');
var QUERY_KEYS = {
    token: 'token',
    return: 'return',
    returnTo: 'returnTo',
    email: 'email',
    state: 'state',
    groupURL: 'groupURL',
};
var CbAuthURLService = /** @class */ (function () {
    function CbAuthURLService(activatedRoute, cbTrainingAppService) {
        this.activatedRoute = activatedRoute;
        this.cbTrainingAppService = cbTrainingAppService;
        this.fragments = undefined;
        this.queryParamsFromURL = null;
        this.getDataFromFragment();
    }
    CbAuthURLService.prototype.getQueryparams = function () {
        var queryParamsKeys = Object.keys(this.activatedRoute.snapshot.queryParams);
        if (queryParamsKeys.length) {
            return this.activatedRoute.snapshot.queryParams;
        }
        return this.getQueryParamsFromURL();
    };
    CbAuthURLService.prototype.getQueryParamsFromURL = function () {
        var _a, _b;
        var search = window.location.search || '';
        if (!search) {
            return null;
        }
        var returnTo = '';
        var searchWithParams = search;
        if (search.includes('&return=')) {
            _a = searchWithParams.split('&return='), searchWithParams = _a[0], returnTo = _a[1];
        }
        else if (search.includes('&returnTo=')) {
            _b = searchWithParams.split('&returnTo='), searchWithParams = _b[0], returnTo = _b[1];
        }
        searchWithParams = searchWithParams.replace('?', '');
        this.queryParamsFromURL = parse(searchWithParams);
        this.queryParamsFromURL['return'] = returnTo;
        this.queryParamsFromURL['returnTo'] = returnTo;
        return this.queryParamsFromURL;
    };
    CbAuthURLService.prototype.getValuesFromFragments = function (frags) {
        return ((frags &&
            frags.split('&').reduce(function (acc, item) {
                var _a = item.split('='), key = _a[0], value = _a[1];
                acc[key] = value;
                return tslib_1.__assign({}, acc);
            }, {})) ||
            {});
    };
    CbAuthURLService.prototype.getDataFromFragment = function () {
        var _a, _b;
        var fragments = this.activatedRoute.snapshot.fragment || window.location.hash || '';
        var extra = fragments;
        var returnURL = '';
        if (fragments.includes('&return=')) {
            _a = fragments.split('&return='), extra = _a[0], returnURL = _a[1];
        }
        else if (fragments.includes('&returnTo=')) {
            _b = fragments.split('&returnTo='), extra = _b[0], returnURL = _b[1];
        }
        extra = extra.replace('#', '');
        var frags = parse(extra);
        frags[QUERY_KEYS.return] = returnURL;
        this.fragments = frags;
        return frags;
    };
    CbAuthURLService.prototype.getParamsFromReturnTo = function () {
        // log('entered getParamsFromReturnTo');
        var returnPath = this.getReturnPath();
        if (!returnPath) {
            return {};
        }
        var _a = returnPath.split('?'), params = _a[1];
        var paramObject = parse(params);
        return paramObject;
    };
    CbAuthURLService.prototype.getAllURLParams = function () {
        var queryParmas = this.getQueryParamsFromURL() || {};
        var fragments = this.getDataFromFragment() || {};
        var params = tslib_1.__assign({}, queryParmas, fragments);
        // @info: cleanup return -> returnTo
        if (params.return) {
            params.returnTo = params.return;
            delete params.return;
        }
        // @info: authorizeURL is a better name than return or returnTo
        params.authorizeURL = params.returnTo || '';
        if (params.authorizeURL) {
            params.authorizeURL = decodeURIComponent(params.authorizeURL);
        }
        return params;
    };
    CbAuthURLService.prototype.getEmailFromFragment = function () {
        return this.fragments[QUERY_KEYS.email];
    };
    CbAuthURLService.prototype.getState = function () {
        return this.activatedRoute.snapshot.queryParams.state;
    };
    CbAuthURLService.prototype.getAuth0ConfigParams = function () {
        // log('entered getConfigFromAuth0Environment');
        var auth0Key = '_auth0Config';
        var config = window[auth0Key] || {};
        return this.flatObject(config) || {};
    };
    CbAuthURLService.prototype.flatObject = function (obj, collection) {
        var _this = this;
        if (obj === void 0) { obj = {}; }
        if (collection === void 0) { collection = {}; }
        if (!obj) {
            return {};
        }
        if (typeof obj !== 'object') {
            return collection;
        }
        var props = Object.keys(obj) || [];
        props.forEach(function (key) {
            var innerValue = obj[key];
            if (typeof innerValue === 'object') {
                return _this.flatObject(innerValue, collection);
            }
            collection[key] = innerValue;
        });
        return collection;
    };
    CbAuthURLService.prototype.getToken = function () {
        var token = this.getAllURLParams().token;
        return token || '';
    };
    CbAuthURLService.prototype.getDecodedToken = function () {
        var jwt = new JwtHelperService();
        var token = this.getToken();
        if (!token) {
            return {};
        }
        var decoded = jwt.decodeToken(token) || {};
        return decoded;
    };
    CbAuthURLService.prototype.getReturnToQueryParams = function () {
        var returnPath = this.getReturnPath();
        if (!returnPath) {
            return {};
        }
        // pageType: authorize or samlp
        var _a = returnPath.split('?'), params = _a[1];
        if (!params) {
            params = '';
        }
        return parse(params) || {};
    };
    CbAuthURLService.prototype.getContinuePath = function (state, token) {
        // log('entered getContinuePath');
        var page = 'continue';
        var returnPath = this.getReturnPath();
        if (!returnPath) {
            throw new Error('There is not return Path to process');
        }
        var continueParams = {};
        // pageType: authorize or samlp
        var _a = returnPath.split('?'), params = _a[1];
        if (!params) {
            params = '';
        }
        var paramObject = parse(params);
        // log(' paramObject ?', { paramObject });
        // @info:
        // Due we are doing a second or third redirection , context.request.query is being cleaned up
        // In order to keep auth0 rules working, we need to manuall pass the neccesary Params
        var onlyInclude = [
            // Groupsio
            'group_id',
            'group_access_type',
            'group_name',
            'group_url',
            // Cvent
            'eventName',
            'event_name',
            // Joinnow
            'project',
            // Registration zoom
            'zoomID',
            'zoom_id',
            // todo: remove! , get it form the returnTo URL
            'registrationType',
            'registration_type',
            'id',
            // Default values
            'app',
            'title',
            'clientName',
            'client_name',
            // to generate next redirection
            '_ga',
            'e',
            'RelayState',
            'client_id',
            'protocol',
            'redirect_uri',
            'response_type',
            'nonce',
        ];
        continueParams = Object.keys(paramObject).reduce(function (acc, key) {
            if (onlyInclude.includes(key) && !acc[key] && key !== 'undefined') {
                acc[key] = paramObject[key] || '';
            }
            return acc;
        }, {});
        // log('continueParams ? ', { continueParams });
        var queryParams = stringify(tslib_1.__assign({ state: state,
            token: token }, continueParams));
        // log('queryParams ? ', { queryParams });
        var continuePath = page + "?" + queryParams;
        // log('continue Path ?', { continuePath });
        return continuePath;
    };
    CbAuthURLService.prototype.HandleOverwritesByApps = function (queryParams) {
        var trainingAppOverwrites = this.cbTrainingAppService.HandleOverwritesByApp() || {};
        return tslib_1.__assign({}, queryParams, trainingAppOverwrites);
    };
    // @info: Generate a valid authorize URL
    // providing (always): client_id, response_type and redirect_uri
    CbAuthURLService.prototype.generateAuthorizeURLInAuth0Server = function () {
        var auth0Key = '_auth0Config';
        var config = window[auth0Key];
        if (!config) {
            return '';
        }
        var returnPath = 'authorize';
        var extraParams = config.extraParams || {};
        var internalOptions = config.internalOptions || {};
        if ((extraParams.protocol || internalOptions.protocol || '') === 'samlp') {
            returnPath = "samlp/" + config.clientID;
        }
        var queryParams = {};
        if (!returnPath.includes('samlp')) {
            queryParams = {
                client_id: config.clientID,
            };
        }
        Object.keys(config.extraParams).forEach(function (key) {
            if (key && key !== 'undefined') {
                queryParams[key] = config.extraParams[key];
            }
        });
        queryParams = this.HandleOverwritesByApps(queryParams);
        if (!returnPath.includes('samlp')) {
            if (!queryParams['response_type']) {
                queryParams['response_type'] = queryParams['type'];
            }
            if (!queryParams['redirect_uri']) {
                queryParams['redirect_uri'] = queryParams['redirectURI'] || config.callbackURL || '';
            }
        }
        var returnQuery = stringify(queryParams);
        var returnURL = encodeURIComponent(returnPath + "?" + returnQuery);
        return returnURL;
    };
    CbAuthURLService.prototype.getReturnPath = function (extraParams) {
        if (extraParams === void 0) { extraParams = null; }
        var _a, _b, _c, _d;
        var authorizeURL = this.getAllURLParams().authorizeURL;
        var path = authorizeURL || this.generateAuthorizeURLInAuth0Server();
        if (!path) {
            return '';
        }
        if (path.includes('samlp')) {
            while (!path.includes('samlp/')) {
                path = decodeURIComponent(path);
            }
            // Safe Cleanup
            if (path.includes('&return=')) {
                _a = path.split('&return='), path = _a[1];
            }
            else if (path.includes('&returnTo=')) {
                _b = path.split('&returnTo='), path = _b[1];
            }
            return path;
        }
        if (!path.includes('authorize')) {
            return '';
        }
        while (!path.includes('authorize?')) {
            path = decodeURIComponent(path);
        }
        // Safe Cleanup
        if (path.includes('&return=')) {
            _c = path.split('&return='), path = _c[1];
        }
        else if (path.includes('&returnTo=')) {
            _d = path.split('&returnTo='), path = _d[1];
        }
        var extraQueries;
        if (extraParams) {
            extraQueries = stringify(extraParams);
            if (extraQueries === 'undefined') {
                extraQueries = '';
            }
        }
        var finalPath = path + "&" + extraQueries;
        return finalPath;
    };
    // Used in Groups IO flow
    CbAuthURLService.prototype.getGroupURL = function () {
        return this.fragments[QUERY_KEYS.groupURL] || '';
    };
    CbAuthURLService.prototype.isSignupFirstPage = function () {
        var flags = ['signup', 'signUp'];
        var search = document.location.href;
        if (!search) {
            return false;
        }
        var isSignup = flags.some(function (flag) {
            return search.includes(flag);
        });
        if (isSignup) {
            return true;
        }
        return false;
    };
    CbAuthURLService.ngInjectableDef = i0.defineInjectable({ factory: function CbAuthURLService_Factory() { return new CbAuthURLService(i0.inject(i1.ActivatedRoute), i0.inject(i2.CbTrainingAppService)); }, token: CbAuthURLService, providedIn: "root" });
    return CbAuthURLService;
}());
export { CbAuthURLService };
