import { Injectable } from '@angular/core';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';

import { HttpService } from '../services/http.service';
import { EmailResponse } from '../models/http.models';
import { CbLoginConfig } from './cb-login.config';
import { ResendVerificationQueryParams } from '../models/query-params.models';
import { CreateAccountUiErrorMessages, fields, DATA_PATH, CbAuthServiceErrorMessage } from './cb-auth-error-messages';
import { CBTokenUser } from '../models/cb-auth.models';

import { LogFactory } from './cb-debug';
const log = LogFactory('CbBackendService', false);

@Injectable({
  providedIn: 'root',
})
export class CbBackendService {
  errorMessages = new CbAuthServiceErrorMessage();

  constructor(private loginConfig: CbLoginConfig, private httpService: HttpService) {}

  getUser(email, token = ''): Observable<CheckUserResponse> {
    const route = this.loginConfig.cbBackend;
    const queryParams = [
      { name: 'email', value: email },
      { name: 'token', value: token },
    ];

    return this.httpService.get(`${route}/user/check-user`, { queryParams }, token);
  }

  getUserEmails(token) {
    log('entered getUserEmails', { token });

    const route = this.loginConfig.cbBackend;
    return this.httpService.get(`${route}/user/emails`, {}, token);
  }

  createEmailToken(token, email) {
    log('entered createEmailToken', { token, email });

    const route = this.loginConfig.cbBackend;
    const requestBody = {
      email,
      token,
    };
    return this.httpService.post(`${route}/user/create-email-token`, { requestBody }, token);
  }

  resetPassword(
    usernameEmail: string,
    captchaToken: string,
    returnValue: string = '',
    intent: number = 1
  ): Observable<EmailResponse> {
    const route = this.loginConfig.cbBackend;
    const requestBody: any = {
      usernameEmail,
      intent,
      captchaToken,
      returnTo: returnValue ? encodeURIComponent(returnValue) : '',
    };

    return this.httpService
      .post(route + '/user/reset-password', { requestBody })
      .pipe(map((response: EmailResponse) => response));
  }

  changePassword(password: string, token: string): Observable<EmailResponse> {
    const route = this.loginConfig.cbBackend;
    return this.httpService
      .post(route + '/user/change-password', {
        queryParams: [
          { name: 'password', value: password },
          { name: 'token', value: token },
        ],
      })
      .pipe(map((response: any) => response));
  }
  checkResetPasswordToken(token: string): Observable<any> {
    const route = this.loginConfig.cbBackend;
    return this.httpService
      .post(route + '/user/check-reset-password', {
        queryParams: [{ name: 'token', value: token }],
      })
      .pipe(map((response: any) => response));
  }

  resendVerficationEmail(email: string, options: ResendVerificationQueryParams): Observable<any> {
    const route = this.loginConfig.cbBackend;

    const queryParams = [{ name: 'email', value: email }];

    if (options.state) {
      const decodedState = decodeURIComponent(options.state);
      queryParams.push({ name: 'state', value: decodedState });
    }

    if (options.intent) {
      queryParams.push({ name: 'intent', value: options.intent.toString() });
    }

    if (options.return) {
      const decodedReturn = decodeURIComponent(options.return);
      queryParams.push({ name: 'return', value: decodedReturn });
    }

    return this.httpService
      .post(route + '/user/resend-verification-email', { queryParams })
      .pipe(map((response: any) => response));
  }

  verifyAccount(token: string, options: ResendVerificationQueryParams): Observable<Auth0user> {
    const route = this.loginConfig.cbBackend;

    const queryParams = [{ name: 'token', value: token }];

    const optionkeys = Object.keys(options);
    optionkeys.forEach((optionKey) => {
      queryParams.push({ name: optionKey, value: options[optionKey] });
    });

    return this.httpService
      .post(route + '/user/verify-account', { queryParams })
      .pipe(map((response: any) => response));
  }

  canResendVerification(email: string, token: string): Observable<any> {
    const route = this.loginConfig.cbBackend;

    const queryParams = [{ name: 'email', value: email }];

    if (token) {
      queryParams.push({ name: 'token', value: token });
    }

    if (this.loginConfig.cbSsoStaticApp) {
      queryParams.push({ name: 'ssoStaticApp', value: 'true' });
    }

    return this.httpService
      .get(route + '/user/can-resend-verification', { queryParams })
      .pipe(map((response: any) => response));
  }

  markEmailAsVerified(token) {
    const base = this.loginConfig.cbBackend;

    const route = `${base}/user/mark-as-verified`;
    const requestBody = {
      token,
    };

    return this.httpService.post(route, { requestBody });
  }

  createAccount(user: CBTokenUser): Observable<any> {
    const { cbBackend } = this.loginConfig;
    const accountUrl = `${cbBackend}/user/accounts`;
    const userBody = { ...user };

    return this.httpService.post(accountUrl, { requestBody: userBody }).pipe(
      switchMap((response) => {
        if (response.error) {
          return throwError(response.error);
        }
        return of(response);
      }),
      catchError((response) => {
        return throwError(response.error);
      })
    );
  }

  private handleCreateAccountError(error: any): Observable<never> {
    const err = error.code ? error : error.error;

    if (err.code === 'conflict_error') {
      return this.handleCreateAccount409Error();
    }

    return this.handleCreateAccount400Error(error);
  }

  private handleCreateAccount409Error() {
    return throwError({
      errorField: 'username',
      errorMessage: CreateAccountUiErrorMessages.ACCOUNT_EXISTS,
    });
  }

  private handleCreateAccount400Error(error: any) {
    const { details: errorDetails = { dataPath: '' } } = error;
    let uiError: { errorField: fields; errorMessage: string } = {
      errorField: '',
      errorMessage: CreateAccountUiErrorMessages.GENERIC,
    };

    switch (errorDetails.dataPath as DATA_PATH) {
      case DATA_PATH.USERNAME:
        uiError = {
          errorField: 'username',
          errorMessage: this.errorMessages.username,
        };
        break;
      case DATA_PATH.EMAIL:
        uiError = {
          errorField: 'email',
          errorMessage: this.errorMessages.email,
        };
        break;
      case DATA_PATH.PASSWORD:
        uiError = {
          errorField: 'password',
          errorMessage: this.errorMessages.password,
        };
        break;
      case DATA_PATH.FIRST_NAME:
        uiError = {
          errorField: 'firstName',
          errorMessage: this.errorMessages.firstName,
        };
        break;
      case DATA_PATH.LAST_NAME:
        uiError = {
          errorField: 'lastName',
          errorMessage: this.errorMessages.lastName,
        };
        break;
      case DATA_PATH.IS_AGREEMENT_ACCEPTED:
        uiError = {
          errorField: 'isAgreementAccepted',
          errorMessage: this.errorMessages.isAgreementAccepted,
        };
        break;
      default:
        uiError = {
          errorField: '',
          errorMessage: this.errorMessages.generic,
        };
    }

    return throwError(uiError);
  }
}

export interface UserMetadata {
  isAgreementAccepted: boolean;
  given_name: string;
  family_name: string;
  verification_token: string;
}

export interface Identity {
  user_id: string;
  provider: string;
  connection: string;
  isSocial: boolean;
}

export interface Auth0user {
  email: string;
  username: string;
  email_verified: boolean;
  user_metadata: UserMetadata;
  updated_at: Date;
  user_id: string;
  name: string;
  picture: string;
  nickname: string;
  identities: Identity[];
  created_at: Date;
  last_ip: string;
  last_login: Date;
  logins_count: number;
  newToken: string;
}

export interface CheckUserResponse {
  data: boolean;
}
