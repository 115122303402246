<div
  class="flex flex-1 flex-column bg-cover"
  [ngStyle]="{ 'background-image': (clientBackground && 'url(' + clientBackground + ')') || '' }"
>
  <div class="content-section main-container container flex-0 mb-0-mobile">
    <cb-top-banner (hideLogin)="showLogin = false"></cb-top-banner>
  </div>

  <div class="content-section main-container container flex flex-column flex-1" *ngIf="showLogin">
    <div class="content-section-inner">
      <div class="page-content columns is-centered flex-0">
        <div class="column" *ngIf="isLoading">
          <cb-loading-page loadingMessage="We are processing your request, please wait"></cb-loading-page>
        </div>
        <ng-container *ngIf="!isLoading">
          <div class="page-settings float-sign-out">
            <div *ngIf="displaySettings" class="has-text-weight-bold">
              SETTINGS
            </div>
            <div *ngIf="myProfileURLdisplayed$ | async">
              <a [href]="myProfileURL" target="_blank" class="font-14px has-text-black">Manage Profile</a>
            </div>
            <div>
              <a *ngIf="displaySignOut" class="font-14px has-text-black" (click)="signOut()">
                Sign Out
              </a>
            </div>
          </div>
          <div class="page-content-inner clear-both column">
            <router-outlet></router-outlet>
          </div>
        </ng-container>
      </div>
      <div class="is-divider-vertical p-0 is-hidden-tablet"></div>

      <div
        *ngIf="cbLoginBannerService.appMetadata && cbLoginBannerService.appMetadata.faqURL"
        class="page-footer columns"
      >
        <div class="column has-text-centered mt-2 font-12px">
          <a class="cb-link skyblue" [href]="cbLoginBannerService.appMetadata.faqURL" target="_blank">
            {{ cbLoginBannerService.appMetadata.faqText }}
          </a>
        </div>
      </div>
      <div class="page-footer columns">
        <div
          class="column has-text-centered font-12px font-source-sans-pro"
          [ngClass]="{
            'pt-0': cbLoginBannerService.appMetadata && cbLoginBannerService.appMetadata.faqURL,
            'mt-2': !cbLoginBannerService.appMetadata || !cbLoginBannerService.appMetadata.faqURL
          }"
        >
          <span>
            Have a Question?
          </span>
          Read
          <a class="cb-link skyblue" href="https://docs.linuxfoundation.org/lfx/sso" target="_blank">
            Documentation
          </a>
          or
          <a class="cb-link skyblue" cbNavigateTo="/cb/help">Contact Us</a>
        </div>
      </div>
      <div class="footer-logo-container">
        <ng-container *ngIf="shouldShowFooterLogo">
          <cb-bottom-banner *ngIf="cbLoginBannerService.bottomImages.length"></cb-bottom-banner>
        </ng-container>
      </div>
    </div>
  </div>
</div>
